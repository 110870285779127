import { defineStore } from 'pinia'

export interface FrameData {
    canBuy: boolean
    session: string
    defaultWarehouse: string
}

export const useFrameStore = defineStore({
    id: 'frame',
    state: () => ({
        data: {
            canBuy: false,
            session: '',
            defaultWarehouse: '',
        } as FrameData,
        isRedirecting: false,
    }),
    getters: {
        userCanBuy: (state) => state.data.canBuy,
        defaultWarehouse: (state) => state.data.defaultWarehouse,
    },
    actions: {
        updateFrameData(data: FrameData) {
            this.data = data
        },
        setIsRedirecting(isRedirecting: boolean) {
            this.isRedirecting = isRedirecting
        },
        postMessage(type: string, data?: unknown) {
            window.parent.postMessage({ type, data }, '*')
        }
    }
})
